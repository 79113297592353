import {gql} from '@apollo/client'

export const INTEGRATE = gql`
  mutation Integrate($input: IntegrationLogInput!) @api(name: "integrations") {
    integrate(input: $input) {
      id
      accountId
      integrationId
      action
      data
      assetId
    }
  }
`
export const UPSERT_GOOGLE_INTEGRATION = gql`
  mutation UpsertGoogleSheet($id: ID, $payload: GoogleSheetInput!)
  @api(name: "integrations") {
    upsertGoogleSheet(id: $id, payload: $payload) {
      success
      message
      syncedAt
    }
  }
`

export const CONNECT_TO_GOOGLE = gql`
  mutation ConnectToGoogle($input: ConnectToGooglePayload!)
  @api(name: "integrations") {
    connectToGoogle(input: $input) {
      success
      message
      createdAt
    }
  }
`
