import {useCallback} from 'react'
import {useLazyQuery, useMutation} from '@apollo/client'
import {
  FETCH_GOOGLE_SHEETS,
  FETCH_GOOGLE_SHEET_BY_SCHEMA_ID,
} from '../../queries'
import {UPSERT_GOOGLE_INTEGRATION} from '../../mutations'
import {GoogleSheetInput, UseGoogleIntegrationsOutput} from '../../types'

export const useGoogleIntegrations = (): UseGoogleIntegrationsOutput => {
  const [lazyFetchGoogleSheets, fetchGoogleSheetsResults] =
    useLazyQuery(FETCH_GOOGLE_SHEETS)
  const fetchGoogleSheets = useCallback(() => {
    lazyFetchGoogleSheets()
  }, [lazyFetchGoogleSheets])

  const [lazyFetchSheetById, fetchBySchemaIdResult] = useLazyQuery(
    FETCH_GOOGLE_SHEET_BY_SCHEMA_ID
  )
  const fetchGoogleSheetBySchemaId = useCallback(
    (schemaId: string) => {
      lazyFetchSheetById({
        variables: {schemaId},
      })
    },
    [lazyFetchSheetById]
  )

  const [doGoogleIntegrate, upsertGoogleSheetResponse] = useMutation(
    UPSERT_GOOGLE_INTEGRATION
  )
  const upsertGoogleSheet = useCallback(
    (payload: GoogleSheetInput) => {
      return doGoogleIntegrate({
        variables: {payload},
      })
    },
    [doGoogleIntegrate]
  )

  return {
    fetchGoogleSheets,
    fetchGoogleSheetsResults,
    fetchGoogleSheetBySchemaId,
    fetchBySchemaIdResult,
    upsertGoogleSheet,
    upsertGoogleSheetResponse,
  }
}
