/* eslint-disable max-lines */
import {PencilSquareIcon} from '@heroicons/react/24/outline'
import {Banner, Button, Pagination, Table} from '@myadbox/gatsby-theme-nebula'
import {useDatasets} from '@myadbox/nebula-service-api'
import {FieldArray, FieldArrayRenderProps, Form, Formik} from 'formik'
import {navigate} from 'gatsby'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect, useMemo, useState} from 'react'
import DeleteDatasetModal from '../DeleteDatasetModal/DeleteDatasetModal'
import EditRowForm from '../EditRowForm'
import {getUsageDescription} from '../helpers'
import {CheckboxCell} from './CheckboxCell'
import EditableDataTableHeaders from './EditableDataTableHeaders'
import {
  ActionsWrapper,
  getEditableTableSchema,
  getUsageMap,
  handleSubmit,
} from './helpers'
import {EditableDataTableProps} from './types'

export const EditableDataTable = ({
  fields,
  datasets,
  page,
  totalPages,
  usageData,
  usageLoading,
  hasDatasets,
  schemaId,
  refetchDatasets,
  nestedSchemaFields,
  isGoogleSynced,
}: EditableDataTableProps) => {
  const [editingRecordId, setEditingRecordId] = useState(``)
  const [updating, setUpdating] = useState(false)
  const {t} = useTranslation()

  const {
    addDataset,
    addDatasetResult: {data: addData, loading: addLoading, error: addError},
    updateDataset,
    updateDatasetResult: {
      data: updateData,
      loading: updateLoading,
      error: updateError,
    },
  } = useDatasets()

  useEffect(() => {
    if (updating) {
      if (addData || updateData) {
        setEditingRecordId(``)
        if (addData && page !== totalPages) {
          navigate(`/settings/schemas/${schemaId}/${totalPages}`)
        }
        setUpdating(false)
        refetchDatasets()
      }
    }
  }, [
    addData,
    page,
    refetchDatasets,
    schemaId,
    totalPages,
    updateData,
    updating,
  ])

  const usageMap = useMemo(
    () => getUsageMap(usageData?.datasetUsage),
    [usageData]
  )

  const addDatasetHandler = (arrayHelpers: FieldArrayRenderProps) => {
    setEditingRecordId(`new`)
    arrayHelpers.push({
      id: `new`,
      data: {
        ...fields.reduce(
          (acc, field) => ({
            ...acc,
            [field.name]: ``,
          }),
          {}
        ),
      },
    })
  }

  return (
    <div className={`pb-6`}>
      {(addError || updateError) && (
        <Banner intent="error">
          {addError?.message || updateError.message}
        </Banner>
      )}
      <Formik
        initialValues={{rows: datasets || []}}
        validateOnChange={false}
        validationSchema={getEditableTableSchema(fields)}
        onSubmit={values => {
          setUpdating(true)
          handleSubmit({
            values,
            recordId: editingRecordId,
            addDataset,
            updateDataset,
            schemaId,
            nestedSchemaFields,
          })
        }}
        enableReinitialize
      >
        <Form
          className={`w-full overflow-x-auto overflow-y-clip md:w-[calc(100vw-8rem)]`}
        >
          <FieldArray name="rows">
            {arrayHelpers => (
              <>
                <Table contrast style={{minWidth: 120 * (fields.length + 2)}}>
                  <EditableDataTableHeaders
                    schemaId={schemaId}
                    fields={fields}
                    refetchDatasets={refetchDatasets}
                    isGoogleSynced={isGoogleSynced}
                  />
                  <Table.Body>
                    {hasDatasets &&
                      datasets.map((row, index) => (
                        <Table.Row key={row.id}>
                          {editingRecordId === row.id ? (
                            <EditRowForm
                              key={editingRecordId}
                              setRecordId={setEditingRecordId}
                              rowIndex={index}
                              rows={fields}
                              loading={updateLoading}
                            />
                          ) : (
                            <>
                              {fields.map(field => (
                                <CheckboxCell
                                  key={field.name}
                                  rowData={row.data}
                                  name={field.name}
                                  checkboxName={`rows.${index}.data.${field.name}`}
                                  type={field.type}
                                  isEditing={editingRecordId === row.id}
                                />
                              ))}

                              <Table.Cell>
                                {usageLoading ? (
                                  <span
                                    className={`
                                      bg-ui-100
                                      text-xs
                                      text-transparent
                                    `}
                                  >
                                    {t`settings.loading`}
                                  </span>
                                ) : (
                                  getUsageDescription(
                                    usageMap?.[row.id]?.assetsCount || 0,
                                    usageMap?.[row.id]?.usersCount || 0,
                                    t
                                  )
                                )}
                              </Table.Cell>
                              {!isGoogleSynced && (
                                <Table.Cell className={`sticky right-0`}>
                                  <ActionsWrapper>
                                    <Button
                                      size="xs"
                                      slim
                                      variant="text"
                                      type="button"
                                      title={t`settings.schemas.editDatasetBtn`}
                                      onClick={() => setEditingRecordId(row.id)}
                                    >
                                      <PencilSquareIcon width={18} />
                                    </Button>
                                    <DeleteDatasetModal
                                      schemaId={schemaId}
                                      datasetId={row.id}
                                      usage={usageMap?.[row.id]}
                                      refetchDatasets={refetchDatasets}
                                    />
                                  </ActionsWrapper>
                                </Table.Cell>
                              )}
                            </>
                          )}
                        </Table.Row>
                      ))}
                    {editingRecordId === `new` && (
                      <Table.Row>
                        <EditRowForm
                          key={editingRecordId}
                          setRecordId={setEditingRecordId}
                          rowIndex={datasets?.length || 0}
                          rows={fields}
                          loading={addLoading}
                          remove={arrayHelpers.pop}
                        />
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                <div
                  className={`
                    mt-6
                    flex
                    w-[calc(100vw-2rem)] justify-between
                    md:w-[calc(100vw-8rem)]
                  `}
                >
                  <Button
                    variant="outline"
                    disabled={
                      fields.length === 0 ||
                      editingRecordId === `new` ||
                      isGoogleSynced
                    }
                    // @ts-expect-error, tests will fail because they treat button as submit by default, but we need this to be button outside of testing
                    type={window.Cypress ? `button` : `submit`}
                    onClick={() => addDatasetHandler(arrayHelpers)}
                  >
                    + {t`settings.schemas.addDatasetBtn`}
                  </Button>
                  {fields.length > 0 && hasDatasets && (
                    <Pagination
                      page={page}
                      totalPages={totalPages}
                      muted={datasets.length < 20 && page === 1}
                      arrowProps={{
                        left: {
                          to: `/settings/schemas/${schemaId}/${page - 1}`,
                          onClick: () => setEditingRecordId(``),
                        },
                        right: {
                          to: `/settings/schemas/${schemaId}/${page + 1}`,
                          onClick: () => setEditingRecordId(``),
                        },
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </FieldArray>
        </Form>
      </Formik>
    </div>
  )
}

export default EditableDataTable
