import qs from 'qs'

const TITAN_PORT = process.env.TITAN_PORT || 3099
export interface CognitoAuthToken {
  accessToken?: {
    jwtToken: string
    payload: {
      exp: string
    }
  }
  refreshToken?: {
    token: string
  }
}
const getLanguageLocale = (language: string): string => language.split(`-`)[0]

export const getCoreAppUrl = (
  domain: string,
  product: string,
  token: CognitoAuthToken,
  lang = `en`
): string => {
  if (isEmpty(token)) return `${domain}/${product}`

  const params = buildParams(token, product, lang)
  return `${domain}/nebula_login?${params}`
}

export const getTitanAppUrl = (
  protocol = `https:`,
  hostname = `localhost`,
  jwt,
  subRoute = ``
): string => {
  const validationRoute = `/validate/${jwt}`
  if (hostname === `localhost`)
    return `${protocol}//${hostname}:${TITAN_PORT}${validationRoute}${subRoute}`

  const accountName = hostname.split(`.`)[0]
  const primaryDomain = hostname.replace(/.*?\./, ``)

  return `${protocol}//${accountName}.compliance.${primaryDomain}${validationRoute}${subRoute}`
}

export const buildParams = (
  token: CognitoAuthToken,
  product: string,
  language: string
): string => {
  if (isEmpty(token)) return ``
  const lang = getLanguageLocale(language)

  return qs.stringify(
    {
      access_token: token.accessToken?.jwtToken,
      refresh_token: token.refreshToken?.token,
      exp: token.accessToken?.payload?.exp,
      redirect_url: `/${product}`,
      lang,
    },
    {encode: false}
  )
}

export const isEmpty = (obj): boolean => {
  if (!obj) return true
  if (typeof obj !== `object`) return true
  return Object.keys(obj).length === 0
}
