import {ApolloError} from '@apollo/client'
import {Banner, PageActionHeader, SEO, Text} from '@myadbox/gatsby-theme-nebula'
import {useFeatureFlags} from '@myadbox/gatsby-theme-nebula/hooks'
import {GoogleSheetsAuthModal} from '@myadbox/integrations-core'
import {Integration, isSesimiAdmin} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {GoogleSheetName} from '../helpers'
import {IntegrationCard} from './IntegrationCard'
export interface IntegrationListProps {
  integrations: Integration[]
  activeIntegrationIds: string[]
  error?: ApolloError
}

export const IntegrationList = ({
  integrations,
  activeIntegrationIds,
  error,
}: IntegrationListProps) => {
  const {t} = useTranslation()
  const {
    featureFlags: {showSocialAppsIntegration},
  } = useFeatureFlags()
  if (!isSesimiAdmin()) {
    return <PageActionHeader text={t`settings.integrations.invalidUser`} />
  }

  const disabledIntegrations: Integration[] = []
  const enabledIntegrations: Integration[] = []
  const defaultIntegrations: Integration[] = []

  integrations.forEach((integration: Integration) => {
    if (integration.isActive) {
      if (activeIntegrationIds?.includes(integration.id)) {
        enabledIntegrations.push(integration)
      } else if (integration.isEnabledByDefault && showSocialAppsIntegration) {
        defaultIntegrations.push(integration)
      } else if (!integration.isEnabledByDefault) {
        disabledIntegrations.push(integration)
      }
    }
  })

  const displayIntegrations = !!(
    enabledIntegrations.length ||
    disabledIntegrations.length ||
    defaultIntegrations.length
  )

  return (
    <>
      <SEO title={t`settings.integrations.title`} />
      <PageActionHeader text={t`settings.integrations.subtitle`} />
      {error && (
        <Banner intent="error">{t`settings.integrations.error`}</Banner>
      )}
      {!error && integrations.length === 0 && (
        <Banner intent="info">{t`settings.integrations.noIntegrations`}</Banner>
      )}
      {displayIntegrations && (
        <div className="grid-flow-rows grid gap-4">
          <Text variant="heading">{t`settings.integrations.activeHeader`}</Text>
          {(activeIntegrationIds === null ||
            activeIntegrationIds.length === 0) &&
            defaultIntegrations.length === 0 && (
              <Banner intent="info">{t`settings.integrations.noActiveIntegrations`}</Banner>
            )}

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {defaultIntegrations?.map((integration: Integration) => {
              return (
                <IntegrationCard
                  key={`${integration.id}-default-integration`}
                  integration={integration}
                  enabled={true}
                  showAsDefault={!!integration.isEnabledByDefault}
                />
              )
            })}
            {enabledIntegrations?.map((integration: Integration) => {
              return (
                <IntegrationCard
                  key={`${integration.id}-enabled-integration`}
                  integration={integration}
                  enabled={true}
                />
              )
            })}
          </div>
          {disabledIntegrations.length > 0 && (
            <Text variant="heading">{t`settings.integrations.libraryHeader`}</Text>
          )}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {disabledIntegrations?.map((integration: Integration) => {
              let propsToPass = {
                key: `${integration.id}-disabled-integration`,
                integration: integration,
                enabled: false,
                buttonModal: null,
              }
              if (integration.name === GoogleSheetName) {
                propsToPass = {
                  ...propsToPass,
                  buttonModal: (
                    <GoogleSheetsAuthModal integrationsId={integration.id} />
                  ),
                }
              }
              return <IntegrationCard {...propsToPass} />
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default IntegrationList
