import {CheckIcon} from '@heroicons/react/24/outline'
import {Input, Modal} from '@myadbox/gatsby-theme-nebula'
import {ActionGroup, Button, GoogleSheetsIcon, Text} from '@myadbox/stellar-ui'
import {Form, Formik} from 'formik'
import {useGoogleIntegrations} from 'packages/data/nebula-service-api'
import {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {useGoogleModalStore} from '../hooks'
import {GoogleSheetInput, GoogleSheetPayload} from '../types'

const GoogleIntegrationsForm = ({
  id,
  refetch = () => {},
}: {
  id: string
  refetch?: () => void
}) => {
  const setGoogleModalOpen = useGoogleModalStore(state => state.setModalOpen)
  const setContentState = useGoogleModalStore(state => state.setContentState)
  const {
    upsertGoogleSheet,
    upsertGoogleSheetResponse: {error, data, loading},
  } = useGoogleIntegrations()

  const {t} = useTranslation(`googleIntegrations`)

  const initialValues: GoogleSheetPayload = {
    url: ``,
    uniqueColumn: ``,
    sheetName: ``,
  }

  const googleSheetRegex =
    /^(https:\/\/docs\.google\.com\/spreadsheets\/d\/)?([a-zA-Z0-9_#-]{1,44})+(\/edit\?usp=sharing)?$/

  const schemaLocales = {
    regex: t(`connect.validation.regex`),
    spaces: t(`connect.validation.spaces`),
    length: t(`connect.validation.length`),
    required: t(`connect.validation.required`),
  }

  const syncFormSchema = Yup.object().shape({
    url: Yup.string()
      .url(schemaLocales.regex)
      .matches(googleSheetRegex, schemaLocales.regex)
      .required(schemaLocales.required),
    uniqueColumn: Yup.string()
      .matches(/^\S*$/, schemaLocales.spaces)
      .min(3, schemaLocales.length)
      .required(schemaLocales.required),
    sheetName: Yup.string().required(schemaLocales.required),
  })

  const syncDataBlock = (
    values: GoogleSheetPayload,
    nestedSchemaId: string
  ): void => {
    const googleSheetsInput: GoogleSheetInput = {
      ...values,
      nestedSchemaId,
    }
    upsertGoogleSheet(googleSheetsInput)
  }

  const handleCloseForm = useCallback(() => {
    refetch()
    setGoogleModalOpen()
    setContentState(`connected`)
    window.location.reload()
  }, [refetch, setContentState, setGoogleModalOpen])

  useEffect(() => {
    if (data?.upsertGoogleSheet && !error) {
      setTimeout(handleCloseForm, 1000)
    }
  }, [data, error, handleCloseForm])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={syncFormSchema}
      onSubmit={(values: GoogleSheetPayload) => syncDataBlock(values, id)}
    >
      {({isValid}) => (
        <Form>
          <Modal.Body>
            <div className="flex flex-col items-start justify-center gap-3">
              <GoogleSheetsIcon size={30} />
              <Text>{t(`connect.body`)}</Text>
              <Text>{t(`connect.body2`)}</Text>
              <Input.Formik
                name={`url`}
                id={`google-sheets-url`}
                label={`URL`}
                type={`text`}
                placeholder={`Enter Google Sheet URL`}
              />
              <Input.Formik
                name={`uniqueColumn`}
                id={`google-sheets-unique-column`}
                label={`Unique Column`}
                type={`text`}
                placeholder={`Enter Unique Column name`}
              />
              <Input.Formik
                name={`sheetName`}
                id={`google-sheets-sheet-name`}
                label={`Sheet Name`}
                type={`text`}
                placeholder={`Enter Sheet name`}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              {error && <Text>{t(`connect.error`)}</Text>}
              {!loading && data?.upsertGoogleSheet ? (
                <div
                  className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
                >
                  <CheckIcon width={24} height={24} />
                  <Text>{t`connect.synced`}</Text>
                </div>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={setGoogleModalOpen}
                  >
                    {t(`connect.cancel`)}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!isValid || loading}
                    loading={loading}
                    loadingText={t`connect.updating`}
                  >
                    {t(`connect.connect`)}
                  </Button>
                </>
              )}
            </ActionGroup>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default withTranslationProvider(GoogleIntegrationsForm)
