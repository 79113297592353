/* eslint-disable max-lines */
import {
  //ClockIcon,
  Cog6ToothIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'
import {
  Banner,
  Button,
  Dropdown,
  Input,
  SEO,
  TableEmptyState,
  TableLoadingState,
} from '@myadbox/gatsby-theme-nebula'

import {useDatasets, useGoogleIntegrations} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {HTMLAttributes, useCallback, useEffect, useMemo, useState} from 'react'
import CsvUploaderModal from './CsvUploaderModal'
import EditableDataTable from './EditableDataTable'

import {useDebouncedCallback} from 'use-debounce'
import EditColumnsModal from './EditColumnsModal/EditColumnsModal'
import SchemaHeader from './SchemaHeader/SchemaHeader'
import {
  downloadCsvTemplate,
  downloadSchemaStructure,
  getSortedFields,
} from './helpers'
import {navigate} from 'gatsby'
import {
  GoogleIntegrationsModal,
  GoogleIntegrationsTrigger,
} from '@myadbox/integrations-core'
import {useFeatureFlags} from '@myadbox/gatsby-theme-nebula/hooks'
import {GoogleSheetsIcon, Toast} from '@myadbox/stellar-ui'

interface Props extends HTMLAttributes<HTMLElement> {
  id: string
  page?: number
}

const LIMIT = 50 //max datasets to fetch
const MIN_CHARACTERS = 3

const SchemaView = ({id, page: defaultPage}: Props) => {
  const {t} = useTranslation()

  const {
    fetchSchema,
    fetchSchemaResult: {loading, error, data},
    fetchAllDatasetsBySchema,
    fetchAllDatasetsBySchemaResult: {data: allData, loading: allLoading},
    fetchDatasetsBySchema,
    fetchDatasetsBySchemaResult: {
      data: paginatedData,
      loading: paginatedLoading,
    },
    fetchDatasetsUsage,
    fetchDatasetsUsageResult: {data: usageDataNew, loading: usageLoading},
  } = useDatasets()
  const {
    fetchGoogleSheetBySchemaId,
    fetchBySchemaIdResult: {data: googleIntegration},
  } = useGoogleIntegrations()
  const {fetchGoogleSheetBySchemaId: googleSheet} = googleIntegration ?? {}

  const {
    featureFlags: {showGoogleSheetsConnection},
  } = useFeatureFlags()

  const isFetchedDatasetsBySchema =
    paginatedData?.datasetsBySchema && !paginatedLoading
  const [keyword, setKeyword] = useState(``)
  const [autoDownloadTriggered, setAutoDownloadTriggered] = useState(false)
  const [showCsvUploaderModal, setShowCsvUploaderModal] = useState(false)
  const offset = defaultPage && defaultPage > 1 ? (defaultPage - 1) * LIMIT : 0
  const paginatedDatasets = paginatedData?.datasetsBySchema || []
  const hasDatasets = paginatedDatasets?.length > 0
  const page = defaultPage || 1
  const datasetCount = keyword
    ? paginatedDatasets.length
    : data?.nestedSchema?.datasetsCount ?? 0
  const totalPages = hasDatasets ? Math.ceil(datasetCount / LIMIT) : 1
  const hasGoogleIntegration = showGoogleSheetsConnection && !!googleSheet

  const refetchGoogleIntegration = useCallback(() => {
    fetchSchema(id)
    fetchGoogleSheetBySchemaId(id)
  }, [fetchGoogleSheetBySchemaId, fetchSchema, id])

  useEffect(() => {
    fetchDatasetsBySchema(id, LIMIT, offset, keyword)
    refetchGoogleIntegration()
  }, [
    id,
    fetchDatasetsBySchema,
    defaultPage,
    offset,
    keyword,
    refetchGoogleIntegration,
  ])

  useEffect(() => {
    const datasetIds = (paginatedData?.datasetsBySchema || []).map(
      dataset => dataset.id
    )
    if (datasetIds.length > 0) {
      fetchDatasetsUsage(datasetIds)
    }
  }, [fetchDatasetsUsage, paginatedData?.datasetsBySchema])

  useEffect(() => {
    const {allDatasetsBySchema} = allData || {}
    if (allDatasetsBySchema) {
      if (!autoDownloadTriggered && allDatasetsBySchema?.url) {
        window.open(allDatasetsBySchema.url, `_blank`)
        setAutoDownloadTriggered(true)
      }
      if (allDatasetsBySchema.background) {
        setShowCsvUploaderModal(true)
      }
    }
  }, [allData, autoDownloadTriggered])

  const fields = useMemo(
    () => getSortedFields(data?.nestedSchema?.descendants),
    [data?.nestedSchema?.descendants]
  )

  const successMessage = t(`settings.schemas.connectedGoogleSheet`)
  const showSuccess =
    hasGoogleIntegration &&
    Boolean(fields.length) &&
    isFetchedDatasetsBySchema &&
    !googleSheet.sync

  useEffect(() => {
    if (showSuccess) {
      Toast.show(<Toast intent="success">{successMessage}</Toast>)
    }
  }, [successMessage, showSuccess])

  const refetchDatasets = () => {
    fetchDatasetsBySchema(id, LIMIT, offset, keyword)
  }

  const handleCsvDownloadClick = () => {
    const {allDatasetsBySchema} = allData || {}
    if (allDatasetsBySchema) {
      if (allDatasetsBySchema.url) {
        window.open(allDatasetsBySchema.url, `_blank`)
      } else {
        setShowCsvUploaderModal(true)
      }
    } else {
      fetchAllDatasetsBySchema(id)
    }
  }

  const debounced = useDebouncedCallback(value => {
    if (value.length >= MIN_CHARACTERS) {
      navigate(`/settings/schemas/${id}/${1}`)
      setKeyword(value)
    } else {
      setKeyword(``)
    }
  }, 1000)

  return (
    <>
      <SEO title="Schemas" />
      <div
        className={`
          until-md:flex-col
          flex
          flex-row
          justify-between
        `}
      >
        <SchemaHeader
          fetched={isFetchedDatasetsBySchema}
          schema={data?.nestedSchema}
        />
        <div
          className={`
            until-md:mb-4
            mb-4
            grid
            grid-cols-[auto_1fr_auto_auto]
            gap-2
            self-end
          `}
        >
          {data?.nestedSchema && (
            <>
              {/* TODO: Put back once we get paginated search */}
              {hasGoogleIntegration && (
                <div className="flex flex-row items-center justify-center gap-1 text-sm">
                  <GoogleSheetsIcon size={24} />
                  {t`settings.schemas.view.connectToGoogle`}
                </div>
              )}
              <Input
                type="search"
                id="dataset-search"
                name="dataset-search"
                label="Search dataset"
                placeholder="Search data..."
                labelHidden
                onChange={e => debounced(e.target.value)}
              />
              <Dropdown
                trigger={
                  <Dropdown.Button
                    id="schema-download-menu-trigger"
                    variant="secondary"
                    disabled={hasGoogleIntegration}
                  >
                    {t`settings.schemas.view.downloadBtn`}
                  </Dropdown.Button>
                }
              >
                <Dropdown.Item
                  disabled={!hasDatasets || allLoading}
                  onSelect={handleCsvDownloadClick}
                >
                  {allLoading
                    ? t`settings.schemas.view.downloadDataBtnLoadingLbl`
                    : t`settings.schemas.view.downloadDataBtnLbl`}
                </Dropdown.Item>

                <Dropdown.Item
                  onSelect={() =>
                    downloadCsvTemplate(data?.nestedSchema.name, fields)
                  }
                >
                  {t`settings.schemas.csvTemplateBtnLabel`}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={!hasDatasets}
                  onSelect={() => downloadSchemaStructure(data?.nestedSchema)}
                >
                  {t`settings.schemas.structureDownloadBtnTitle`}
                </Dropdown.Item>
              </Dropdown>

              {hasGoogleIntegration && isFetchedDatasetsBySchema ? (
                <Dropdown
                  trigger={
                    <Dropdown.Button
                      id="schema-updload-menu-trigger"
                      variant="secondary"
                    >
                      {t`settings.schemas.view.uploadBtn`}
                    </Dropdown.Button>
                  }
                >
                  <CsvUploaderModal
                    nestedSchema={data?.nestedSchema}
                    refetchDatasets={refetchDatasets}
                    disabled={!fields.length || hasGoogleIntegration}
                    isListItem
                  />
                  <GoogleIntegrationsTrigger
                    type={`LIST`}
                    text={`connect.trigger`}
                    disabled={Boolean(fields.length) && !hasGoogleIntegration}
                  />
                </Dropdown>
              ) : (
                <CsvUploaderModal
                  nestedSchema={data?.nestedSchema}
                  refetchDatasets={refetchDatasets}
                  disabled={!fields.length}
                />
              )}
            </>
          )}
        </div>
      </div>
      {showCsvUploaderModal && (
        <div className="mb-4">
          <Banner
            intent="success"
            fadeIn
            onDismiss={() => setShowCsvUploaderModal(false)}
          >{t`settings.schemas.datablockRequestMessage`}</Banner>
        </div>
      )}

      {isFetchedDatasetsBySchema && Boolean(fields.length) && (
        <EditableDataTable
          fields={fields}
          hasDatasets={hasDatasets}
          datasets={paginatedDatasets}
          page={page}
          totalPages={totalPages}
          usageData={usageDataNew}
          usageLoading={usageLoading}
          searchDatasetsLoading={paginatedLoading}
          schemaId={id}
          refetchDatasets={refetchDatasets}
          nestedSchemaFields={data?.nestedSchema.descendants}
          isGoogleSynced={hasGoogleIntegration}
        />
      )}

      {isFetchedDatasetsBySchema && !hasGoogleIntegration && !fields.length && (
        <TableEmptyState
          paddingVertical="2rem"
          icon={<Cog6ToothIcon width={24} height={24} />}
          text={t`settings.schemas.noFieldsMsg`}
          actionNode={
            <div className="flex flex-col items-center justify-center gap-2">
              <EditColumnsModal
                schemaId={id}
                fields={fields}
                refetchDatasets={refetchDatasets}
                trigger={open => (
                  <Button
                    onClick={open}
                    variant="primary"
                    type="button"
                    title={t(`settings.schemas.columnSettings.triggerBtn`)}
                  >
                    {t(`settings.schemas.columnSettings.addColumns`)}
                  </Button>
                )}
              />
              {showGoogleSheetsConnection && (
                <>
                  {t(`settings.schemas.columnSettings.or`)}
                  <GoogleIntegrationsTrigger
                    type="BUTTON"
                    text={`connect.trigger`}
                  />
                </>
              )}
            </div>
          }
        />
      )}
      {isFetchedDatasetsBySchema &&
        !hasGoogleIntegration &&
        !hasDatasets &&
        Boolean(fields.length) && (
          <TableEmptyState
            paddingVertical="2rem"
            className={`mt-14`}
            icon={<TableCellsIcon width={24} height={24} />}
            text={t`settings.schemas.noDatasetsMsg`}
            actionNode={
              <CsvUploaderModal
                nestedSchema={data?.nestedSchema}
                refetchDatasets={refetchDatasets}
              />
            }
          />
        )}
      {(loading || paginatedLoading) && <TableLoadingState />}
      {error && (
        <Banner intent="error">
          {t`settings.schemas.failedToFetch` || error.message}
        </Banner>
      )}
      {(!id ||
        (isFetchedDatasetsBySchema && !loading && !data?.nestedSchema)) && (
        <Banner intent="error">{t`settings.schemas.invalidSchemaMsg`}</Banner>
      )}
      <GoogleIntegrationsModal id={id} refetch={refetchGoogleIntegration} />
    </>
  )
}

export default SchemaView
