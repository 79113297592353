import {
  ApolloQueryResult,
  MutationResult,
  OperationVariables,
  QueryResult,
} from '@apollo/client'
import {
  Field,
  Options,
  SelectFieldOptions,
} from '@myadbox/nebula-template-utils'

export interface LanguageValue {
  [key: string]: string
}

export type IntegrationCategoryUnion =
  | `SOCIAL`
  | `PRINTER`
  | `SESIMI_SUITE`
  | `SPREADSHEET`

export interface Integration {
  id: string
  name: string
  description: LanguageValue
  intro: LanguageValue
  logo?: string
  config: IntegrationConfig
  category: IntegrationCategoryUnion
  isEnabledByDefault?: boolean
  isActive?: boolean
}

export interface GoogleSheet {
  id?: string
  spreadsheetId: string
  nestedSchemaId: string
  integrationId: string
  accountId: string
  url: string
  sheetName: string
  sync?: Sync
  uniqueColumn: string
  createdBy: string
  updatedBy?: string
}
export interface UpsertGoogleSheetResponse {
  success: boolean
  message: string
  syncedAt: Date
}

export interface Sync {
  time: Date
  lastUpdated: Date
}

export interface GoogleSheetInput {
  url: string
  uniqueColumn: string
  sheetName: string
  nestedSchemaId: string
}

export enum ActionType {
  PRINT_ORDER_CREATED = `PRINT_ORDER_CREATED`,
}

export interface IntegrationLog {
  integrationId: string
  action?: ActionType
  data?: Record<string, unknown>
  assetId?: string
}

export type IntegrationLogInput = IntegrationLog

export interface IntegrationConfig {
  fields: IntegrationField[]
}

export interface IntegrationField extends Omit<Field, `type`> {
  options: IntegrationFieldOptions
}

export interface DatasetFieldOptions extends Options {
  selectionCriteria: string[]
}

export interface ModifierFieldOptions extends Options {
  modifier: `percentage` | `fixed`
}

export type IntegrationFieldOptions =
  | Options
  | DatasetFieldOptions
  | SelectFieldOptions
  | ModifierFieldOptions

export interface UseIntegrationsOutput {
  fetchIntegrations(): void
  fetchIntegrationsResults: QueryResult<
    {integrations: Integration[]},
    OperationVariables
  >
  integrate(input: IntegrationLogInput): void
  integrateResponse: MutationResult<{integrate: IntegrationLog}>
}

export interface ConnectToGoogleLog {
  integrationId: string
  authCode: string
}

export type ConnectToGoogleLogInput = ConnectToGoogleLog

export interface UseGoogleConnectOutput {
  connectToGoogle(input: ConnectToGoogleLogInput): void
  googleConnectResponse: MutationResult<{
    connectToGoogle: {
      success: string
      message: string
      createdAt: string
    }
  }>
}

export interface ConnectToGoogleInput {
  integrationId: string
  authCode: string
}

export interface UseGoogleIntegrationsOutput {
  fetchGoogleSheets(): void
  fetchGoogleSheetsResults: ApolloQueryResult<{integrations: GoogleSheet[]}>
  fetchGoogleSheetBySchemaId(schemaId: string): void
  fetchBySchemaIdResult: QueryResult<
    {fetchGoogleSheetBySchemaId: GoogleSheet},
    OperationVariables
  >
  upsertGoogleSheet(input: GoogleSheetInput): void
  upsertGoogleSheetResponse: MutationResult<{
    upsertGoogleSheet: UpsertGoogleSheetResponse
  }>
}
